.loader {
    opacity: 1;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(128, 128, 128, 0.393);
}
.cm-spinner {
    height: 100px;
    width: 100px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top: 4px solid #f15e41;
    -webkit-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
    position: relative;
  }
  
  .cm-spinner::before,
  .cm-spinner::after {
    content: "";
    position: absolute;
    top: 6px;
    bottom: 6px;
    left: 6px;
    right: 6px;
    border-radius: 50%;
    border: 4px solid transparent;
  }
  
  .cm-spinner::before {
    border-top-color: #bad375;
    -webkit-animation: 3s spin linear infinite;
    animation: 3s spin linear infinite;
  }
  
  .cm-spinner::after {
    border-top-color: #26a9e0;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }   
               
  @-webkit-keyframes spin {
      from {
          -webkit-transform: rotate(0deg);
           transform: rotate(0deg);
      }
      to {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }   
          
  @keyframes spin {
      from {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      to {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }
  