@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

.paginationConatiner{
  display: flex;
  justify-content: center;
}
.active a{
  
  background-color: #c6c6c6  !important;
  border-color: #c6c6c6 !important;
  box-shadow: 0 0 0 0;
}
#container {
  margin: 1rem;
}

.items {
  margin-bottom: 1rem;
}